import { FC, memo, useCallback, useEffect, useState } from 'react'

import {
	Print as PrinterIcon,
	Receipt as ReceiptIcon,
	Replay as RetryIcon
} from '@mui/icons-material'
import {
	Box,
	Button,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography
} from '@mui/material'

import { FileType } from '../../../@types/File'
import { usePrinter } from '../../../hooks/usePrinter'
import { FileListItem } from '../../FileListItem/FileListItem'
import { Props } from './types'

const ConfirmationCheck: FC<{ onConfirm: () => void }> = memo(
	({ onConfirm }) => (
		<Box>
			<Box display="flex" justifyContent="center" fontSize={112} mb={2}>
				<PrinterIcon fontSize="inherit" />
			</Box>
			<Box textAlign="center" mb={4}>
				<Typography variant="h4">Testing printer</Typography>
			</Box>
			<Box>
				<Button
					variant="contained"
					sx={{ mb: 1 }}
					onClick={onConfirm}
					fullWidth>
					Print test receipt
				</Button>
			</Box>
		</Box>
	)
)

const LoadingCheck: FC = memo(() => (
	<Box>
		<Box display="flex" justifyContent="center" fontSize={112} mb={2}>
			<PrinterIcon fontSize="inherit" />
		</Box>
		<Box textAlign="center" mb={4}>
			<Typography variant="h4">Testing printer</Typography>
			<Typography variant="subtitle1">
				Please wait
				<br />
				Printing test receipt
			</Typography>
		</Box>
	</Box>
))

const LoadedCheck: FC<{ onFail: () => void; onNext: () => void }> = memo(
	({ onFail, onNext }) => (
		<Box>
			<Box display="flex" justifyContent="center" fontSize={112} mb={2}>
				<ReceiptIcon fontSize="inherit" />
			</Box>
			<Box textAlign="center" mb={4}>
				<Typography variant="h4">Check printed receipt</Typography>
				<Typography variant="subtitle1">
					We just printed a test receipt
					<br />
					Please look whether it worked out
				</Typography>
			</Box>
			<Box>
				<Button
					variant="contained"
					color="success"
					sx={{ mb: 1 }}
					onClick={onNext}
					fullWidth>
					Print was successful
				</Button>
				<Button
					variant="contained"
					color="error"
					fullWidth
					onClick={onFail}>
					Print failed
				</Button>
			</Box>
		</Box>
	)
)

const FailedCheck: FC<{ onRetry: () => void }> = memo(({ onRetry }) => (
	<Box>
		<Box display="flex" justifyContent="center" fontSize={112} mb={2}>
			<ReceiptIcon fontSize="inherit" />
		</Box>
		<Box mb={4}>
			<Typography textAlign="center" variant="h4">
				If printing failed...
			</Typography>
			<Typography variant="subtitle1">
				Make sure that:
				<List disablePadding>
					<ListItem disableGutters>
						1. There is enough paper in the printer
					</ListItem>
					<ListItem disableGutters>
						2. The Bluetooth connection to the printer is up and
						running
					</ListItem>
				</List>
			</Typography>
		</Box>
		<Box>
			<List>
				<FileListItem
					title="How to swap printer paper"
					src="https://merchant-public-training-materials.s3.eu-west-1.amazonaws.com/merchant_paper_training.mp4"
					fileType={FileType.VIDEO}
				/>
				<FileListItem
					title="Bluetooth troubleshooting"
					src="https://merchant-public-training-materials.s3.eu-west-1.amazonaws.com/merchant_bluetooth_training.mp4"
					fileType={FileType.VIDEO}
				/>
				<ListItem disablePadding disableGutters divider>
					<ListItemButton disableGutters onClick={onRetry}>
						<ListItemText>Print again</ListItemText>
						<ListItemIcon>
							<Typography color="primary" mr={2}>
								Retry
							</Typography>
							<RetryIcon color="primary" />
						</ListItemIcon>
					</ListItemButton>
				</ListItem>
			</List>
			<Box display="flex" justifyContent="center" pt={2}>
				<Typography variant="caption">
					If you are still having issues, call our merchant support
				</Typography>
			</Box>
		</Box>
	</Box>
))

export const PrinterCheck: FC<Props> = memo(
	({ loadingStation, loadingArea, onNext }) => {
		const [showRetry, setShowRetry] = useState(false)
		const [confirmedPrint, setConfirmedPrint] = useState(false)
		const { printingTestPage, printTestPage } = usePrinter()
		const handlePrintTestPage = useCallback(() => {
			setShowRetry(false)
			printTestPage(loadingArea, loadingStation)
		}, [loadingArea, loadingStation, printTestPage])
		useEffect(() => {
			if (confirmedPrint) {
				handlePrintTestPage()
			}
		}, [confirmedPrint, handlePrintTestPage])
		const showFailed = !printingTestPage && showRetry
		const showLoading = printingTestPage
		const showConfirmation = !confirmedPrint

		if (showConfirmation) {
			return (
				<ConfirmationCheck onConfirm={() => setConfirmedPrint(true)} />
			)
		}
		if (showFailed) {
			return <FailedCheck onRetry={handlePrintTestPage} />
		}
		if (showLoading) {
			return <LoadingCheck />
		}

		return <LoadedCheck onNext={onNext} onFail={() => setShowRetry(true)} />
	}
)
