import linewrap from 'linewrap'

import { getSettingsFromStore } from '../../utils/localStorage'
import { qrCodeSizes, receiptFontSizes } from './receiptFontSizes'
import { PrintSettings } from './types'

export const getPrintSettings = (): PrintSettings => {
	const settings = getSettingsFromStore()
	return {
		fontSize: receiptFontSizes[settings.accessibility.receiptFontScale],
		qrCodeSize: qrCodeSizes[settings.accessibility.receiptFontScale]
	}
}

export const wrap = (
	string_: string,
	preservedLineIndent: number,
	wrapLineIndent: number,
	lineLength = 29
) => {
	// linewrap lib seems pretty nice, but is curiosly incapable of collapsing multiple linefeeds
	// so we are working around it to stop customers from spending too much printer paper
	const processed = string_.replace(/([\n\r|]+)/g, '\r\n')
	const wrapper = linewrap(lineLength, {
		wrapLineIndent,
		preservedLineIndent,
		whitespace: 'collapse',
		respectLineBreaks: 'm1',
		lineBreak: '\r\n'
	})
	return wrapper(processed)
}
