import { FC, useCallback, useEffect, useState } from 'react'

import { Box } from '@mui/material'

import { StationStatus } from '../../@types/Order'
import { setSectionStatus } from '../../actions'
import { useVenueFeatureFlags } from '../../hooks/useVenueFeatureFlags'
import { soundService } from '../../services/sound'
import { useAppDispatch } from '../../utils/storeHelpers'
import { OrderEditable } from '../OrderEditable'
import { OrderLarge } from '../OrderLarge'
import { Action } from '../OrderRevamp/Action'
import { ActionType } from '../OrderRevamp/Action/types'
import { OrderActions } from '../OrderRevamp/Layout/OrderActions'
import { OrderCard } from '../OrderRevamp/Layout/OrderCard'
import { OrderContent } from '../OrderRevamp/Layout/OrderContent'
import { OrderModal } from '../OrderRevamp/Layout/OrderModal'
import { OrderCancelledInnerProps, OrderCancelledProps } from './types'

const SOUND_PLAY_INTERVAL_MS = 30 * 1000 // 30s

export const OrderCancelled: FC<OrderCancelledProps> = ({ order }) => {
	const { useRevampedUI } = useVenueFeatureFlags()
	const dispatch = useAppDispatch()
	const [isModalVisible, setModalVisible] = useState(false)

	const handleAck = useCallback(() => {
		dispatch(
			setSectionStatus(
				order.loadingareaId,
				order.loadingstationId,
				order._id,
				order.order_id,
				StationStatus.COMPLETED,
				order.station_status_hr
			)
		)
	}, [
		dispatch,
		order._id,
		order.loadingareaId,
		order.loadingstationId,
		order.order_id,
		order.station_status_hr
	])

	const toggleModal = useCallback(() => {
		setModalVisible((state) => !state)
	}, [])

	useEffect(() => {
		soundService.playSound()

		const interval = setInterval(() => {
			soundService.playSound()
		}, SOUND_PLAY_INTERVAL_MS)

		return () => {
			clearInterval(interval)
		}
	}, [])

	if (useRevampedUI) {
		return (
			<>
				<OrderCancelledInnerRevamped
					order={order}
					onAck={handleAck}
					onViewContents={toggleModal}
				/>

				<OrderModal
					isOpen={isModalVisible}
					onRequestClose={toggleModal}
					width={900}>
					<OrderEditable
						order={order}
						isScrollable
						onClose={toggleModal}
						actions={
							<>
								<Action
									type={ActionType.ACK_CANCELLATION}
									onClick={handleAck}
									displayStyle="fullWidth"
								/>
							</>
						}
					/>
				</OrderModal>
			</>
		)
	}

	return (
		<>
			<OrderCancelledInner
				order={order}
				onAck={handleAck}
				onViewContents={toggleModal}
			/>

			<OrderModal isOpen={isModalVisible} onRequestClose={toggleModal}>
				<OrderLarge
					order={order}
					isScrollable
					actions={
						<>
							<Action
								type={ActionType.CLOSE_WINDOW}
								testId="ActionCloseWindow"
								onClick={toggleModal}
							/>
							<Action
								type={ActionType.ACK_CANCELLATION}
								onClick={handleAck}
							/>
						</>
					}
					hideTimer
				/>
			</OrderModal>
		</>
	)
}

export const OrderCancelledInner: FC<OrderCancelledInnerProps> = ({
	order,
	onAck,
	onViewContents
}) => {
	return (
		<OrderCard col>
			<OrderContent className="!flex-col items-center justify-center text-red">
				<span className="text-2xl font-bold">Order {order._id}</span>
				<span className="mb-4 text-2xl font-bold">
					has been cancelled!
				</span>

				<span>
					Please halt preparation and acknowledge cancellation.
				</span>
			</OrderContent>
			<OrderActions>
				<Action
					type={ActionType.VIEW_CONTENTS}
					testId="ActionViewContents"
					onClick={onViewContents}
				/>
				<Action
					type={ActionType.ACK_CANCELLATION}
					testId="ActionAckCancellation"
					onClick={onAck}
				/>
			</OrderActions>
		</OrderCard>
	)
}

export const OrderCancelledInnerRevamped: FC<OrderCancelledInnerProps> = ({
	order,
	onAck,
	onViewContents
}) => {
	return (
		<OrderCard col>
			<OrderContent className="!flex-col items-center justify-center text-red">
				<span className="text-2xl font-bold">Order {order._id}</span>
				<span className="mb-4 text-2xl font-bold">
					has been cancelled!
				</span>

				<span>
					Please halt preparation and acknowledge cancellation.
				</span>
			</OrderContent>
			<Box flex={1} my={2} mx={2}>
				<Box mb={2}>
					<Action
						type={ActionType.VIEW_CONTENTS}
						testId="ActionViewContents"
						onClick={onViewContents}
						displayStyle="fullWidth"
					/>
				</Box>
				<Action
					type={ActionType.ACK_CANCELLATION}
					testId="ActionAckCancellation"
					onClick={onAck}
					displayStyle="fullWidth"
				/>
			</Box>
		</OrderCard>
	)
}
