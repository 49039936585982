import { EpsonPrint } from '@baemingo/epson-printer-sdk'

export const epsonGenerateReportReceipt = () => {
	const print = new EpsonPrint()

	return print
		.addText('Report receipt not implemented!')
		.addFeedLine(10)
		.addCut(undefined as any)
}
