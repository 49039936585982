import { useCallback } from 'react'

import { useSettingsContext } from '../../components/SettingsContext'
import { container } from '../../container'
import { generateKitchenReport } from '../../printer'
import { analytics } from '../../services/analytics'
import { epsonGenerateReportReceipt } from '../../services/printer/epson/epsonGenerateReportReceipt'
import { epsonPrintReceipt } from '../../services/printer/epson/epsonPrintReceipt'
import { enqueuePrintErrorSnackbar, enqueuePrintSuccessSnackbar } from './utils'

export const usePrintReportReceipt = () => {
	const {
		settings: { shouldUseEpsonPrinter, epsonPrinterIp }
	} = useSettingsContext()

	const printEpsonReceipt = useCallback(async () => {
		const receipt = epsonGenerateReportReceipt()

		try {
			const res = await epsonPrintReceipt({
				ip: epsonPrinterIp,
				receipt
			})

			enqueuePrintSuccessSnackbar('Report receipt printed')

			analytics.trackEvent('Print Report Receipt Success', {
				Printer: 'Epson'
			})

			return res
		} catch (error: any) {
			enqueuePrintErrorSnackbar('Failed to print report receipt')

			analytics.trackEvent('Print Report Receipt Failure', {
				Printer: 'Epson',
				Error_Message: error?.message ?? 'Unknown error'
			})
		}
	}, [epsonPrinterIp])

	const printBrotherReceipt = useCallback(
		(summary: any, currency: any, cursor: any) => {
			const receipt = generateKitchenReport(summary, currency, cursor)

			container.printReceipt(receipt)

			// Technically, we don't know if it succeeded or not
			analytics.trackEvent('Print Report Receipt Success', {
				Printer: 'Brother'
			})
		},
		[]
	)

	return useCallback(
		async (summary: any, currency: any, cursor: any) => {
			analytics.trackEvent('Print Report Receipt', {
				Printer: shouldUseEpsonPrinter ? 'Epson' : 'Brother'
			})

			if (shouldUseEpsonPrinter) {
				// Try epson print, if fails, try brother
				try {
					return await printEpsonReceipt()
				} catch {
					return printBrotherReceipt(summary, currency, cursor)
				}
			}

			return printBrotherReceipt(summary, currency, cursor)
		},
		[printBrotherReceipt, printEpsonReceipt, shouldUseEpsonPrinter]
	)
}
