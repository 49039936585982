import { useCallback, useState } from 'react'

import {
	Box,
	Divider,
	FormControlLabel,
	Switch,
	TextField,
	Typography
} from '@mui/material'

import { useDebouncedCallback } from 'use-debounce'

import { useVenueFeatureFlags } from '../../../hooks/useVenueFeatureFlags'
import { analytics } from '../../../services/analytics'
import { useSettingsContext } from '../../SettingsContext'

export const PrinterSelectionEpson = () => {
	const { shouldAllowEpsonPrinter } = useVenueFeatureFlags()

	return shouldAllowEpsonPrinter ? <PrinterSelectionEpsonInner /> : null
}

const PrinterSelectionEpsonInner = () => {
	const { settings, setSettings } = useSettingsContext()
	const [epsonPrinterIp, setEpsonPrinterIp] = useState(
		settings.epsonPrinterIp
	)

	const handleUseEpsonPrinterChange = useCallback(
		(_, value: boolean) => {
			setSettings({ ...settings, shouldUseEpsonPrinter: value })
			analytics.setUserProperty('Use Epson Printer', value)
		},
		[setSettings, settings]
	)

	const handleStorageIpChange = useDebouncedCallback((value) => {
		setSettings({ ...settings, epsonPrinterIp: value })
	}, 333)

	const handleIpChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setEpsonPrinterIp(event.target.value)
			handleStorageIpChange(event.target.value)
		},
		[handleStorageIpChange]
	)

	return (
		<>
			<Divider />

			<FormControlLabel
				labelPlacement="start"
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					mr: 1
				}}
				control={
					<Box flexDirection="row" display="flex" alignItems="center">
						<Typography variant="caption" color="text.secondary">
							{settings.shouldUseEpsonPrinter
								? 'Enabled'
								: 'Disabled'}
						</Typography>
						<Switch
							checked={settings.shouldUseEpsonPrinter}
							onChange={handleUseEpsonPrinterChange}
						/>
					</Box>
				}
				label={
					<Box py={3}>
						<Typography variant="body1">
							Use Epson printer
						</Typography>
						<Typography variant="caption" color="text.secondary">
							Enable if you want to use Epson printer. Receipt
							printing will prioritse this printer and use any
							other printer as fallback.
						</Typography>
					</Box>
				}
			/>

			{settings.shouldUseEpsonPrinter ? (
				<Box mx={2} pb={2}>
					<TextField
						label="Printer IP"
						placeholder="192.168.0.1"
						onChange={handleIpChange}
						value={epsonPrinterIp}
						InputLabelProps={{
							shrink: true
						}}
					/>
				</Box>
			) : null}
		</>
	)
}
