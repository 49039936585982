import { useCallback } from 'react'

import { LoadingArea, LoadingStation } from '../../@types/LoadingArea'
import { useSettingsContext } from '../../components/SettingsContext'
import { container } from '../../container'
import { analytics } from '../../services/analytics'
import { generateTestReceipt } from '../../services/printer'
import { epsonGenerateTestReceipt } from '../../services/printer/epson/epsonGenerateTestReceipt'
import { epsonPrintReceipt } from '../../services/printer/epson/epsonPrintReceipt'
import { enqueuePrintErrorSnackbar, enqueuePrintSuccessSnackbar } from './utils'

export const usePrintTestReceipt = () => {
	const {
		settings: { shouldUseEpsonPrinter, epsonPrinterIp }
	} = useSettingsContext()

	const printEpsonReceipt = useCallback(
		async (loadingarea: LoadingArea, loadingstation: LoadingStation) => {
			const receipt = epsonGenerateTestReceipt(
				loadingarea,
				loadingstation
			)

			try {
				const res = await epsonPrintReceipt({
					ip: epsonPrinterIp,
					receipt
				})

				enqueuePrintSuccessSnackbar('Test receipt printed')

				analytics.trackEvent('Print Test Receipt Success', {
					Printer: 'Epson'
				})

				return res
			} catch (error: any) {
				enqueuePrintErrorSnackbar('Failed to print test receipt')

				analytics.trackEvent('Print Test Receipt Failure', {
					Printer: 'Epson',
					Error_Message: error?.message ?? 'Unknown error'
				})
			}
		},
		[epsonPrinterIp]
	)

	const printBrotherReceipt = useCallback(
		(loadingarea: LoadingArea, loadingstation: LoadingStation) => {
			const receipt = generateTestReceipt(loadingarea, loadingstation)

			container.printReceipt(receipt)

			// Technically, we don't know if it succeeded or not
			analytics.trackEvent('Print Test Receipt Success', {
				Printer: 'Brother'
			})
		},
		[]
	)

	return useCallback(
		async (loadingarea: LoadingArea, loadingstation: LoadingStation) => {
			analytics.trackEvent('Print Test Receipt', {
				Printer: shouldUseEpsonPrinter ? 'Epson' : 'Brother'
			})

			if (shouldUseEpsonPrinter) {
				// Try epson print, if fails, try brother
				try {
					return await printEpsonReceipt(loadingarea, loadingstation)
				} catch {
					return printBrotherReceipt(loadingarea, loadingstation)
				}
			}

			return printBrotherReceipt(loadingarea, loadingstation)
		},
		[printBrotherReceipt, printEpsonReceipt, shouldUseEpsonPrinter]
	)
}
