import React, { useCallback, useRef } from 'react'

import { FeatureFlag } from '../../@types/FeatureFlag'
import { LineItemFulfilment } from '../../@types/LineItem'
import { Order, StationStatus } from '../../@types/Order'
import { setSectionStatus } from '../../actions'
import { usePrintKitchenReceipt } from '../../hooks/printReceipt/usePrintKitchenReceipt'
import { useItemsFulfilment } from '../../hooks/useItemsFulfilment'
import { useVenueFeatureFlags } from '../../hooks/useVenueFeatureFlags'
import { postDebugEvent } from '../../utils'
import { getOrderReusePassQRCode } from '../../utils/order'
import { useAppDispatch } from '../../utils/storeHelpers'
import { Columns } from './Columns'
import { StationProps } from './types'

window.postDebugEvent = postDebugEvent

export const Station = ({ loadingarea, loadingstation }: StationProps) => {
	const { shouldPrintBarcode, shouldPrintDaft } = useVenueFeatureFlags()
	// TODO: This should probably be cleared if an order is no longer relevant. Unnecessary memory pressure.
	const printCountById = useRef<Record<number, number>>({})
	const dispatch = useAppDispatch()
	const {
		mutateAsync: updateItemsFulfilment,
		isLoading: isFulfilling,
		isError: isFulfillingError
	} = useItemsFulfilment()

	const printKitchenReceipt = usePrintKitchenReceipt()

	const handlePrint = useCallback(
		(order: Order) => {
			const itemize = loadingarea.tags.includes(
				FeatureFlag.ITEM_LEVEL_TICKETS
			)

			const qrcode = 'sst-order-' + order.order_id

			const reusePassQRCode = getOrderReusePassQRCode(order)

			printCountById.current[order.order_id] =
				(printCountById.current[order.order_id] || 0) + 1

			// FIXME: What is this even? Why are we limiting the number of prints?
			if (printCountById.current[order.order_id] <= 3) {
				printKitchenReceipt({
					order,
					itemize,
					qrcode,
					shouldPrintBarcode,
					shouldPrintDaft,
					reusePassQRCode
				})
			}

			postDebugEvent('printReceipt', { orderId: order.order_id })
		},
		[
			loadingarea.tags,
			shouldPrintBarcode,
			shouldPrintDaft,
			printKitchenReceipt
		]
	)

	const handleSectionStatusChange = useCallback(
		(order: Order, status: StationStatus) => {
			dispatch(
				setSectionStatus(
					loadingarea._id,
					loadingstation._id,
					order._id,
					order.order_id,
					status,
					order.station_status_hr
				)
			)

			if (status === StationStatus.PREPARING) {
				handlePrint(order)
			}
		},
		[dispatch, handlePrint, loadingarea._id, loadingstation._id]
	)

	const handleItemFulfil = useCallback(
		async (orderId: number, lineItemFulfilment: LineItemFulfilment[]) => {
			updateItemsFulfilment({
				orderId,
				lineItemFulfilment
			})
		},
		[updateItemsFulfilment]
	)

	return (
		<Columns
			loadingArea={loadingarea}
			loadingStation={loadingstation}
			onStatusChange={handleSectionStatusChange}
			onPrint={handlePrint}
			onItemFulfil={handleItemFulfil}
			isFulfilling={isFulfilling}
			isFulfillingError={isFulfillingError}
		/>
	)
}
