import { useCallback } from 'react'

import { Order } from '../../@types/Order'
import { useSettingsContext } from '../../components/SettingsContext'
import { container } from '../../container'
import { analytics } from '../../services/analytics'
import { generateKitchenReceipt } from '../../services/printer'
import { epsonGenerateKitchenReceipt } from '../../services/printer/epson/epsonGenerateKitchenReceipt'
import { epsonPrintReceipt } from '../../services/printer/epson/epsonPrintReceipt'
import { enqueuePrintErrorSnackbar, enqueuePrintSuccessSnackbar } from './utils'

type Params = {
	itemize?: boolean
	order: Order
	qrcode?: string
	shouldPrintBarcode?: boolean
	shouldPrintDaft?: boolean
	reusePassQRCode?: string
}

export const usePrintKitchenReceipt = () => {
	const {
		settings: { shouldUseEpsonPrinter, epsonPrinterIp }
	} = useSettingsContext()

	const printEpsonReceipt = useCallback(
		async ({ order, qrcode }: Params) => {
			const receipt = epsonGenerateKitchenReceipt({
				order,
				qrcodeData: qrcode
			})

			try {
				const res = await epsonPrintReceipt({
					ip: epsonPrinterIp,
					receipt
				})

				enqueuePrintSuccessSnackbar(
					`Order ${order.order_id} receipt printed`
				)

				analytics.trackEvent('Print Kitchen Receipt Success', {
					Printer: 'Epson',
					Order_ID: order.order_id
				})

				return res
			} catch (error: any) {
				enqueuePrintErrorSnackbar(
					`Failed to print order ${order.order_id} receipt`
				)

				analytics.trackEvent('Print Kitchen Receipt Failure', {
					Printer: 'Epson',
					Order_ID: order.order_id,
					Error_Message: error?.message ?? 'Unknown error'
				})
			}
		},
		[epsonPrinterIp]
	)

	const printBrotherReceipt = useCallback((params: Params) => {
		const receipt = generateKitchenReceipt(params)

		container.printReceipt(receipt)

		// Technically, we don't know if it succeeded or not
		analytics.trackEvent('Print Kitchen Receipt Success', {
			Printer: 'Brother',
			Order_ID: params.order.order_id
		})
	}, [])

	return useCallback(
		async (params: Params) => {
			analytics.trackEvent('Print Kitchen Receipt', {
				Printer: shouldUseEpsonPrinter ? 'Epson' : 'Brother',
				Order_ID: params.order.order_id
			})

			if (shouldUseEpsonPrinter) {
				// Try epson print, if fails, try brother
				try {
					return await printEpsonReceipt(params)
				} catch {
					return printBrotherReceipt(params)
				}
			}

			return printBrotherReceipt(params)
		},
		[printBrotherReceipt, printEpsonReceipt, shouldUseEpsonPrinter]
	)
}
