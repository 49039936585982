import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import { FeatureFlag } from '../../../@types/FeatureFlag'
import { useAppSelector } from '../../../utils/storeHelpers'
import { VenueStatus } from '../../VenueStatus'
import { OpeningTimes } from '../OpeningTimes'
import { Props } from './types'

export const VenueInfo = ({ isHorizontal = false }: Props) => {
	const { loadingstationId } = useParams<{ loadingstationId: string }>()

	const [isPauseVisible, setIsPauseVisible] = useState(false)

	const loadingArea = useAppSelector((state) =>
		state.loadingArea.selectedLoadingAreaId
			? state.loadingArea.loadingAreasById[
					state.loadingArea.selectedLoadingAreaId
			  ]
			: null
	)

	const loadingStation = useMemo(() => {
		return loadingArea?.stations.find(
			(station) => station._id === loadingstationId
		)
	}, [loadingArea?.stations, loadingstationId])

	const enableOpenClose = loadingArea?.tags?.includes(
		FeatureFlag.KITCHEN_OPEN_CLOSE
	)

	const togglePauseVisible = useCallback(() => {
		setIsPauseVisible((state) => !state)
	}, [])

	const closePauseVisible = useCallback(() => {
		setIsPauseVisible(false)
	}, [])

	return (
		<Box
			sx={{
				position: 'relative',
				py: 2,
				...(isHorizontal
					? {
							width: '100%'
					  }
					: {})
			}}>
			{/* We need to have a separate "click area" as we don't want the toggle events to leak into
			the modal displayed by VenueStatus */}
			{enableOpenClose && (
				<Box
					data-testid="VenueInfo-TogglePausing"
					sx={{
						position: 'absolute',
						top: 0,
						right: 0,
						bottom: 0,
						left: 0
					}}
					onClick={togglePauseVisible}
				/>
			)}

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					px: 2,
					width: '100%',
					...(isHorizontal
						? {
								flexDirection: 'row',
								justifyContent: 'space-between'
						  }
						: {})
				}}>
				<Typography fontWeight={600} data-testid="StationName">
					{loadingStation?.name ?? '-'}
				</Typography>

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center'
					}}
					data-testid="HoursAndStatus">
					<Typography fontSize={14}>
						<OpeningTimes />
					</Typography>

					{enableOpenClose && (
						<>
							<Typography fontSize={14} ml={1}>
								|
							</Typography>

							<Box ml={1}>
								<VenueStatus
									isModalOpen={isPauseVisible}
									closeModal={closePauseVisible}
								/>
							</Box>
						</>
					)}
				</Box>
			</Box>
		</Box>
	)
}
