/** Used to send signals to container app */
import { ConnectedPrinter, PrinterConnectionType } from './@types/Printer'

let downloadReceipts = false

// Re-enable for receipt debugging
if (process.env.NODE_ENV === 'development') {
	// Toggle receipt download enabled when D pressed
	document.addEventListener('keydown', ({ key }) => {
		if (key === 'd') {
			downloadReceipts = !downloadReceipts
			console.log(
				`Receipt downloads ${downloadReceipts ? 'enabled' : 'disabled'}`
			)
		}
	})
}

function playSound(url = '') {
	if (!window.TiApp) {
		console.log('playSound', url)
		return
	}

	if (url) {
		window.TiApp.fireEvent(
			'playSound',
			JSON.stringify({ bundleId: url ?? 'default_alert' })
		)
		return
	}

	window.TiApp.fireEvent(
		'newOrder',
		JSON.stringify({
			url: 'https://s3-eu-west-1.amazonaws.com/starship-runner/service-bell.mp3'
		})
	)
}

function stopSound() {
	if (!window.TiApp) {
		console.log('stopSound')
		return
	}

	window.TiApp.fireEvent('stopSound', JSON.stringify({}))
}

function clearCookies() {
	if (!window.TiApp) {
		console.log('clearCookies')
		const element = document.createElement('iframe')
		element.setAttribute('src', 'https://www.google.com/accounts/Logout')
		document.body.append(element)
		fetch('/logout', { credentials: 'same-origin', method: 'POST' })
		return
	}

	window.TiApp.fireEvent('clearCookies', JSON.stringify({}))
}

function printReceipt(content = '') {
	// Download receipt data that can be sent to the printer (e.g. Bluetooth File Exchange on MacOS)
	if (process.env.NODE_ENV === 'development' && downloadReceipts) {
		const link = document.createElement('a')
		link.download = 'receipt'
		link.href = `data:application/octet-stream;charset=utf-8;base64,${btoa(
			content
		)}`
		document.body.append(link)
		link.click()
		link.remove()
	}

	if (!window.TiApp) {
		console.log('printReceipt', content)
		return
	}

	window.TiApp.fireEvent(
		'printReceipt',
		JSON.stringify({ content: btoa(content), useActivePrinter: true })
	)
}

function getApiUrl() {
	if (window.TiApp && window.TiApp.getApiUrl) {
		return window.TiApp.getApiUrl()
	}
	// TODO: implement

	if (window.location.hostname.endsWith('sandbox.starship.xyz')) {
		return 'https://merchant.sandbox.starship.xyz/merchantapi'
	}
	return 'https://merchant.starship.xyz/merchantapi'
}
function getDeviceSerial() {
	if (window.TiApp && window.TiApp.getDeviceSerial) {
		return window.TiApp.getDeviceSerial()
	}

	return ''
}

function getDeviceToken(isVerified: boolean) {
	if (process.env.NODE_ENV === 'development') {
		return process.env.REACT_APP_DEV_DEVICE_TOKEN
	}

	const urlParams = new URLSearchParams(window.location.search)
	const deviceExternalToken = urlParams.get('device_external_token')
	if (deviceExternalToken) {
		return deviceExternalToken
	}

	if (window.TiApp && window.TiApp.getDeviceToken) {
		console.log('getDeviceTokenNative', isVerified)
		return window.TiApp.getDeviceToken(isVerified)
	}

	return ''
}

function getWifiMac() {
	if (window.TiApp && window.TiApp.getWifiMac) {
		return window.TiApp.getWifiMac()
	}

	return ''
}

/**
 * returns a promise that is resolved if saving token succeeds, rejects with a reason otherwises
 */
function setDeviceToken(deviceToken: string, isVerified: boolean) {
	const messageId = Math.random().toString(36).slice(7)

	// eslint-disable-next-line sonarjs/cognitive-complexity
	return new Promise((resolve, reject) => {
		// set up a message listener to listen to response of setDeviceToken
		// on the native side via postMessage
		const messageListener = (event) => {
			if (event?.data) {
				try {
					const data = JSON.parse(event.data)

					if (
						data?.type === 'kitchen_native' &&
						data?.messageId === messageId
					) {
						window.removeEventListener('message', messageListener)
						if (
							data.category === 'setDeviceTokenResult' &&
							data.success
						) {
							resolve(data)
							return
						}

						reject(data)
						return
					}
				} catch (error) {
					console.error(error)
				}
			}
		}

		window.addEventListener('message', messageListener)

		if (window.TiApp) {
			window.TiApp.setDeviceToken(messageId, deviceToken, isVerified)
		}
	})
}

function getVersion() {
	if (window.TiApp && window.TiApp.getVersion) {
		return window.TiApp.getVersion()
	}

	return null
}

function getPrinterInterface(): PrinterConnectionType | null {
	if (window.TiApp && window.TiApp.getPrinterInterface) {
		return window.TiApp.getPrinterInterface()
	}

	return null
}

/** Returns connected printer model and connection type */
function getConnectedPrinter(): ConnectedPrinter | Record<string, never> {
	const isPrinterInfoAvailable =
		window.TiApp && window.TiApp.getConnectedPrinter
	if (!isPrinterInfoAvailable) {
		return {}
	}

	try {
		const currentPrinterString = window.TiApp.getConnectedPrinter()

		return JSON.parse(currentPrinterString) || {}
	} catch (error) {
		console.log(error)
		return {}
	}
}

function setPrinterInterface(value: PrinterConnectionType) {
	window.TiApp?.setPrinterInterface?.(value)
}

function requestPrinterStatus() {
	window.TiApp?.fireEvent?.('requestPrinterStatus', '')
}

export const container = {
	playSound,
	stopSound,
	clearCookies,
	printReceipt,
	getVersion,
	getPrinterInterface,
	getConnectedPrinter,
	setPrinterInterface,
	getDeviceSerial,
	getDeviceToken,
	getApiUrl,
	setDeviceToken,
	getWifiMac,
	requestPrinterStatus
}
