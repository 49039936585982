// If we start to have a lot of these, we could have a "config" context or similar.
// It depends on what kind of data we store to localStorage, though.
import { Settings } from '../../@types/Settings'
import { container } from '../../container'

const STATION_SELECTION_STORAGE_KEY = 'selectedStationId'
const SHOW_WELCOME_PAGE_STORAGE_KEY = 'showWelcomePage'
const TAKE_THE_TOUR_STORAGE_KEY = 'takeTheTour'
const MENU_TOUR_STORAGE_KEY = 'menuTour'
const SETTINGS_KEY = 'settings-1.0.0' // versioning - this is bound to be updated at some point

export const getSelectedStationIdFromStore = () => {
	return localStorage.getItem(STATION_SELECTION_STORAGE_KEY)
}

export const setSelectedStationIdToStore = (stationId: string) => {
	localStorage.setItem(STATION_SELECTION_STORAGE_KEY, stationId)
}

export const getSettingsFromStore = () => {
	// for the sake of migration of printing settings, we will add information about the printer
	// we can remove that once all devices run that
	const settings = getJsonParameter<Settings>(SETTINGS_KEY) ?? {
		accessibility: {
			receiptFontScale: 'small'
		},
		usePrinter: true,
		shouldUseEpsonPrinter: false,
		epsonPrinterIp: ''
	}

	const storedPrinterInterface = container.getPrinterInterface()
	const neededPrinter =
		storedPrinterInterface !== 'No printer' ||
		storedPrinterInterface !== null
	const usePrinter =
		settings?.usePrinter === undefined
			? neededPrinter
			: settings?.usePrinter

	return { ...settings, usePrinter }
}

export const setSettingsToStore = (settings: Settings) => {
	return setJsonParameter(SETTINGS_KEY, settings)
}

export const getShowWelcomePage = (): boolean =>
	getBooleanParameter(SHOW_WELCOME_PAGE_STORAGE_KEY)

export const setShowWelcomePage = (showWelcomePage: boolean) => {
	setBooleanParameter(SHOW_WELCOME_PAGE_STORAGE_KEY, showWelcomePage)
}

export const getTakeTheTour = (): boolean =>
	getBooleanParameter(TAKE_THE_TOUR_STORAGE_KEY)

export const setTakeTheTour = (takeTheTour: boolean) => {
	setBooleanParameter(TAKE_THE_TOUR_STORAGE_KEY, takeTheTour)
}

export const getMenuTour = (): boolean =>
	getBooleanParameter(MENU_TOUR_STORAGE_KEY)

export const setMenuTour = (takeTheTour: boolean) => {
	setBooleanParameter(MENU_TOUR_STORAGE_KEY, takeTheTour)
}

const getBooleanParameter = (key: string): boolean => {
	const localValue = localStorage.getItem(key)
	return localValue === null || localValue === 'true'
}

const setBooleanParameter = (key: string, value: boolean) => {
	localStorage.setItem(key, value.toString())
}

const getJsonParameter = <T>(key: string): T | null => {
	const value = localStorage.getItem(key)
	return value ? JSON.parse(value) : null
}

const setJsonParameter = <T>(key: string, value: T) => {
	localStorage.setItem(key, JSON.stringify(value))
}
