import { ReactNode } from 'react'

import { LoadingArea, LoadingStation } from '../../@types/LoadingArea'
import { ServiceAssignment } from '../../@types/Partner'
import { User } from '../../@types/User'
import { VenueConfiguration } from '../../utils/venuesConfiguration/types'

export enum MenuItemType {
	STATION = 0,
	INVENTORY = 1,
	PRINTING = 2,
	REPORT = 3,
	ACCESSIBILITY = 5
}

export type MenuItemProps = {
	customIcon?: ReactNode
	isSelected?: boolean
	label: string
	onClick: () => void
	value?: string | null
	dataTestId: string
}

export type MenuProps = {
	loadingarea: LoadingArea
	loadingareas: LoadingArea[]
	loadingstation: LoadingStation
	serviceAssignmentId: string
	serviceassignments?: Record<string, ServiceAssignment>
	user: User
	venueConfiguration: VenueConfiguration | null
	toggleMenu: () => void
}
