import React, { useCallback } from 'react'

import {
	Box,
	CircularProgress,
	Divider,
	FormControlLabel,
	Grid,
	Switch,
	Typography
} from '@mui/material'

import { usePrinterStatusContext } from '../PrinterStatusContext'
import { useSettingsContext } from '../SettingsContext'
import { HelpCenter } from '../TopBar/HelpCenter'
import { PrinterSelectionEpson } from './PrinterSelectionEpson'

export const PrinterSelection = () => {
	const { settings, setSettings } = useSettingsContext()

	const onEnablePrintingChange = useCallback(
		(_, value: boolean) => {
			setSettings({ ...settings, usePrinter: value })
		},
		[settings, setSettings]
	)

	return (
		<Box px={5} flex={1}>
			<Typography variant="h4" fontWeight="bold" my={3}>
				Printing
			</Typography>

			<Box bgcolor="white" borderRadius={2}>
				<FormControlLabel
					labelPlacement="start"
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						mr: 1
					}}
					control={
						<Box
							flexDirection="row"
							display="flex"
							alignItems="center">
							<Typography
								variant="caption"
								color="text.secondary">
								{settings.usePrinter ? 'Enabled' : 'Disabled'}
							</Typography>
							<Switch
								checked={settings.usePrinter}
								onChange={onEnablePrintingChange}
							/>
						</Box>
					}
					label={
						<Box py={3}>
							<Typography variant="body1">Use printer</Typography>
							<Typography
								variant="caption"
								color="text.secondary">
								Enable if you intend to print receipts for
								orders
							</Typography>
						</Box>
					}
				/>

				{settings.usePrinter ? <PrinterSelectionEpson /> : null}

				{settings.usePrinter ? (
					<>
						<Divider />
						<Box
							sx={{
								borderBottomRightRadius: 8,
								borderBottomLeftRadius: 8
							}}>
							<PrinterConnectionStatus />
						</Box>
					</>
				) : null}
			</Box>
		</Box>
	)
}

const PrinterConnectionStatus = () => {
	const { printerStatus } = usePrinterStatusContext()

	if (printerStatus === undefined) {
		return (
			<Box
				mx={2}
				py={2}
				alignItems="center"
				display="flex"
				flex={1}
				justifyContent="center">
				<CircularProgress size={32} />
			</Box>
		)
	}

	if (printerStatus === null) {
		return (
			<Box mx={2} pb={4}>
				<PrinterErrorDescription
					descriptionCopies={[
						'No printer connected via either USB or Bluetooth.',
						'Visit Help Center for troubleshooting instructions.'
					]}
				/>
			</Box>
		)
	}

	return (
		<Box mx={2} py={4}>
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<Typography fontWeight="bold">Printer</Typography>
				</Grid>
				<Grid item xs={9}>
					<Typography>{printerStatus.printerModel}</Typography>
				</Grid>

				<Grid item xs={3}>
					<Typography fontWeight="bold">Connected via</Typography>
				</Grid>
				<Grid item xs={9}>
					<Typography>
						{printerStatus.connectionInterface === 'BLUETOOTH'
							? 'Bluetooth'
							: 'USB'}
					</Typography>
				</Grid>

				<Grid item xs={3}>
					<Typography fontWeight="bold">Status</Typography>
				</Grid>
				<Grid item xs={9}>
					<Typography>
						{printerStatus.isOnline ? (
							<Box
								component="span"
								color="success.main"
								fontWeight="bold">
								Ready
							</Box>
						) : (
							<Box component="span" color="error.main">
								Offline
							</Box>
						)}
					</Typography>
				</Grid>
			</Grid>

			{/*Printer is paired via Bluetooth, but is not turned on/far away */}
			{!printerStatus.isOnline && (
				<PrinterErrorDescription
					descriptionCopies={[
						"The printer is paired with the tablet, but can't be reached at the moment.",
						'Visit Help Center for troubleshooting instructions.'
					]}
				/>
			)}
		</Box>
	)
}

const PrinterErrorDescription = ({
	descriptionCopies
}: {
	descriptionCopies: string[]
}) => {
	return (
		<Box
			borderRadius={2}
			display="flex"
			flexDirection="row"
			px={4}
			py={4}
			sx={{ backgroundColor: 'error.light' }}
			alignItems="center"
			justifyContent="space-between"
			mt={4}>
			<Box>
				{descriptionCopies.map((copy) => (
					<Typography mb={1}>{copy}</Typography>
				))}
			</Box>

			<Box minWidth={150} ml={2}>
				<HelpCenter />
			</Box>
		</Box>
	)
}
