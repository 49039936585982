import { useCallback, useState } from 'react'

import { LoadingArea, LoadingStation } from '../@types/LoadingArea'
import { sleep } from '../components/PreflightCheck/utils/sleep'
import { usePrintTestReceipt } from './printReceipt/usePrintTestReceipt'

export const usePrinter = () => {
	const [printingTestPage, setPrintingTestPage] = useState(true)
	const printTestReceipt = usePrintTestReceipt()

	const printTestPage = useCallback(
		async (loadingArea: LoadingArea, loadingStation: LoadingStation) => {
			setPrintingTestPage(true)
			try {
				printTestReceipt(loadingArea, loadingStation)
			} catch {
				//
			} finally {
				await sleep(5000)
				setPrintingTestPage(false)
			}
		},
		[printTestReceipt]
	)

	return {
		printingTestPage,
		printTestPage
	}
}
