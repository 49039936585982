import { FC, useCallback, useMemo, useState } from 'react'

import { FeatureFlag } from '../../@types/FeatureFlag'
import { container } from '../../container'
import { usePrintTestReceipt } from '../../hooks/printReceipt/usePrintTestReceipt'
import { useGuidedTour } from '../../utils/guidedTour/useGuidedTour'
import { useTestOrder } from '../../utils/testOrder/useTestOrder'
import { MenuGuidedTour } from '../GuidedTour/MenuGuidedTour'
import { Icon } from '../Icon'
import Inventory from '../Inventory/Inventory'
import { MenuAccessibility } from '../MenuAccessibility'
import { PrinterSelection } from '../PrinterSelection'
import Report from '../Report/Report'
import { useSettingsContext } from '../SettingsContext'
import { StationSelector } from '../StationSelector'
import { TestOrderModal } from '../TestOrderModal'
import { MenuItem } from './MenuItem'
import styles from './index.module.scss'
import { MenuItemType, MenuProps } from './types'

export const Menu: FC<MenuProps> = ({
	serviceAssignmentId,
	loadingareas,
	user,
	serviceassignments,
	loadingarea,
	loadingstation,
	venueConfiguration,
	toggleMenu
}) => {
	const { settings } = useSettingsContext()
	const [selectedItem, setSelectedItem] = useState<MenuItemType | null>(null)
	const [menuCollapsed, setMenuCollapsed] = useState(false)

	const appVersion = useMemo(() => container.getVersion(), [])
	const macAddress = useMemo(() => container.getWifiMac(), [])
	const { showMenuTour } = useGuidedTour(
		venueConfiguration,
		serviceAssignmentId
	)

	const {
		showTestOrderModal,
		setShowTestOrderModal,
		isTestOrderEnabled,
		isOngoingTestOrder,
		isLoaded
	} = useTestOrder(venueConfiguration, serviceAssignmentId, loadingarea._id)

	const handleSignout = useCallback(() => {
		container.clearCookies()

		setTimeout(() => {
			window.location.href = '/'
		}, 1000)
	}, [])

	const closeTestOrderModal = useCallback(() => {
		setShowTestOrderModal(false)
		toggleMenu()
	}, [setShowTestOrderModal, toggleMenu])

	const handleMenuButtonClick = useCallback((itemType: MenuItemType) => {
		setSelectedItem((state) => (state === itemType ? null : itemType))
	}, [])

	const handleReportButtonClick = useCallback(() => {
		handleMenuButtonClick(MenuItemType.REPORT)

		if (window.innerWidth <= 1280) {
			setMenuCollapsed(true)
		}
	}, [handleMenuButtonClick])

	const printTestReceipt = usePrintTestReceipt()

	const showTestPrinting = settings.usePrinter

	return (
		<div className={styles.root}>
			<MenuGuidedTour enabled={showMenuTour} />
			{!menuCollapsed && (
				<div className={styles.list}>
					<div className={styles.user}>
						<div>{user.name}</div>
						<a href="#signout" onClick={handleSignout}>
							Sign out
						</a>
					</div>

					<MenuItem
						dataTestId="MenuStation"
						label="Station"
						value={loadingstation.name}
						onClick={() =>
							handleMenuButtonClick(MenuItemType.STATION)
						}
						isSelected={selectedItem === MenuItemType.STATION}
					/>

					<MenuItem
						dataTestId="MenuInventory"
						label="Inventory"
						onClick={() =>
							handleMenuButtonClick(MenuItemType.INVENTORY)
						}
						isSelected={selectedItem === MenuItemType.INVENTORY}
					/>

					<MenuItem
						dataTestId="MenuAccessibility"
						label="Accessibility"
						isSelected={selectedItem === MenuItemType.ACCESSIBILITY}
						onClick={() =>
							handleMenuButtonClick(MenuItemType.ACCESSIBILITY)
						}
					/>

					{loadingarea?.tags?.includes(
						FeatureFlag.KITCHEN_SHOW_REPORT
					) && (
						<MenuItem
							dataTestId="MenuReport"
							label="Report"
							onClick={handleReportButtonClick}
							isSelected={selectedItem === MenuItemType.REPORT}
						/>
					)}
					<MenuItem
						dataTestId="MenuPrintVia"
						label="Printing"
						onClick={() =>
							handleMenuButtonClick(MenuItemType.PRINTING)
						}
						isSelected={selectedItem === MenuItemType.PRINTING}
					/>

					{showTestPrinting && (
						<MenuItem
							dataTestId="MenuTestPrinter"
							label="Test printer"
							onClick={() => {
								printTestReceipt(loadingarea, loadingstation)
							}}
							customIcon={<Icon icon="printer" />}
						/>
					)}
					{isTestOrderEnabled && isLoaded && (
						<MenuItem
							dataTestId="MenuTestOrder"
							label={
								isOngoingTestOrder
									? 'Test order (ongoing)'
									: 'Create test order'
							}
							onClick={() => setShowTestOrderModal(true)}
							customIcon={<Icon icon="receipt" />}
						/>
					)}

					<div className={styles.footer}>
						{macAddress && <div>Mac {macAddress}</div>}
						Build #{window.BUILD_NUMBER}{' '}
						{appVersion && `(APK: ${appVersion})`}
					</div>
				</div>
			)}

			<div className={styles.content}>
				{selectedItem === null && (
					<div className={styles.placeholder}>
						Please select an option on the left
					</div>
				)}
				{selectedItem === MenuItemType.STATION && (
					<StationSelector
						loadingAreas={loadingareas}
						loadingAreaId={loadingarea._id}
						loadingStationId={loadingstation._id}
					/>
				)}

				{selectedItem === MenuItemType.PRINTING && <PrinterSelection />}

				{selectedItem === MenuItemType.INVENTORY && (
					<Inventory
						serviceassignments={serviceassignments}
						loadingareaId={loadingarea._id}
					/>
				)}

				{selectedItem === MenuItemType.REPORT && (
					<Report serviceAssignmentId={serviceAssignmentId} />
				)}

				{selectedItem === MenuItemType.ACCESSIBILITY && (
					<MenuAccessibility />
				)}
			</div>

			{showTestOrderModal && (
				<TestOrderModal
					serviceAssignmentId={serviceAssignmentId}
					loadingStationId={loadingstation._id}
					loadingAreaId={loadingarea._id}
					onCloseModal={closeTestOrderModal}
					isOngoingTestOrder={isOngoingTestOrder}
				/>
			)}
		</div>
	)
}
