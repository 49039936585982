import { EpsonPrint } from '@baemingo/epson-printer-sdk'
import dayjs from 'dayjs'

import { DeliveryType, Order } from '../../../@types/Order'
import { parseNoteValue } from '../../../utils/lineItem/parseNoteValue'
import { wrap } from '../utils'
import { EpsonPrintExtended } from './epsonPrintExtended'

type Params = {
	order: Order
	qrcodeData?: string
}

export const epsonGenerateKitchenReceipt = ({ order, qrcodeData }: Params) => {
	const print = new EpsonPrintExtended()

	generateHeader(print, order, qrcodeData)

	print.addHorizontalDivider()

	generateOrderContent(print, order)

	return print.addFeedLine(2).addCut(undefined as any)
}

const generateHeader = (
	print: EpsonPrint,
	order: Order,
	qrcodeData?: string
) => {
	// QR code
	if (qrcodeData) {
		print
			.addTextAlign(EpsonPrint.ALIGN_CENTER)
			.addQRCode(qrcodeData, {
				size: 8,
				errorCorrectionLevel: EpsonPrint.LEVEL_1 as any
			})
			.addFeedLine(1)
	}

	// Splits the order id into sets of 3 digits
	const formattedOrderId = order.order_id
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

	// Starship
	print
		.addTextAlign(EpsonPrint.ALIGN_LEFT)
		.addTextSize(1, 1)
		.addText('Starship\n')

	// Order ID
	print
		.addTextSize(1, 2)
		.addTextStyle(false, false, true, EpsonPrint.COLOR_1)
		.addText(
			(order.kiosk_order_id === null
				? formattedOrderId
				: `${order.kiosk_order_id}`) + '\n'
		)

	// Delivery type
	print
		.addTextSize(1, 1)
		.addTextStyle(false, false, false, EpsonPrint.COLOR_1)
		.addText(
			order.delivery_type === DeliveryType.PICKUP
				? 'Customer Pick Up\n'
				: 'Robot Delivery\n'
		)

	// Customer name if exists
	if (order.name_hr) {
		print
			.addTextSize(1, 1)
			.addTextStyle(false, false, false, EpsonPrint.COLOR_1)
			.addText(order.name_hr + '\n')
	}

	const orderPlacedTime = dayjs(order.visible_at).format('hh:mma')
	const placeInRobotTime = dayjs(order.visible_at)
		.add(order.preparation_time_needed ?? 0, 'second')
		.format('hh:mma')

	// Created at
	print
		.addTextSize(1, 1)
		.addTextStyle(false, false, false, EpsonPrint.COLOR_1)
		.addText(`Placed: ${orderPlacedTime}\n`)

	// Load at
	if (order.delivery_type === DeliveryType.TO_CUSTOMER) {
		print.addText(`Load by: ${placeInRobotTime}\n`)
	}

	// Real order ID if Kiosk
	if (order.kiosk_order_id !== null) {
		print
			.addTextSize(1, 1)
			.addTextStyle(false, false, false, EpsonPrint.COLOR_1)
			.addText(formattedOrderId + '\n')
	}
}

const generateOrderContent = (print: EpsonPrint, order: Order) => {
	for (const lineitem of order.lineitems) {
		// Line item name
		print.addTextSize(2, 2).addText(`${lineitem.qty}x ${lineitem.name}\n`)

		const parsedNote = parseNoteValue(lineitem.note)

		const modifierKeys = Object.keys(parsedNote.modifiersByType ?? {})

		for (const modifierKey of modifierKeys) {
			// Modifier name
			print
				.addTextSize(2, 1)
				.addText(wrap(`- ${modifierKey}:\n`, 0, 2, 18))

			const modifiers = parsedNote.modifiersByType?.[modifierKey] ?? []

			for (const modifierValue of modifiers) {
				// Modifier value
				print.addText(wrap(` - ${modifierValue.value}\n`, 2, 4, 16))
			}
		}

		if (parsedNote.message) {
			// Note
			print
				.addTextSize(1, 1)
				.addTextStyle(false, false, true, EpsonPrint.COLOR_1)
				.addText(`Note:\n`)
				.addTextStyle(false, false, false, EpsonPrint.COLOR_1)
				.addText(`${parsedNote.message}\n`)
		}

		// Extra spacing between line items
		print.addFeedLine(1)
	}
}
