import { EpsonPrint } from '@baemingo/epson-printer-sdk'

import { LoadingArea, LoadingStation } from '../../../@types/LoadingArea'
import { EpsonPrintExtended } from './epsonPrintExtended'

export const epsonGenerateTestReceipt = (
	loadingarea: LoadingArea,
	loadingstation: LoadingStation
) => {
	const print = new EpsonPrintExtended()

	const result = print
		.addQRCode(`Starship Kitchen App build ${window.BUILD_NUMBER}`, {
			size: 6
		})
		.addFeedLine(1)
		.addTextSize(2, 1)
		.addTextStyle(false, false, true, EpsonPrint.COLOR_1)
		.addText('Starship Kitchen app\n')
		.addTextSize(1, 1)
		.addTextStyle(false, false, false, EpsonPrint.COLOR_1)
		.addText('Build number: ' + window.BUILD_NUMBER + '\n')
		.addText(loadingarea.name + '\n')
		.addText(loadingstation.name + '\n')

	for (const tag of loadingarea.tags) {
		result.addText('- ' + tag + '\n')
	}

	return result.addFeedLine(2).addCut(undefined as any)
}
