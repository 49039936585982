import { EpsonPrint } from '@baemingo/epson-printer-sdk'

export class EpsonPrintExtended extends EpsonPrint {
	// HLine does not work as expected, we need to fake this
	// This does not go edge to edge on 80mm, to ensure it will fit on 58mm too
	addHorizontalDivider = () => {
		return this.addTextSize(1, 1)
			.addTextStyle(false, false, false, EpsonPrint.COLOR_1)
			.addText('------------------------------------\n')
	}
}
