import { VenueConfiguration } from './types'

const FEATURE_FLAG_TEST_ORDER_KEY = 'test-order:configuration'
const FEATURE_FLAG_TEST_ORDER_HIDE_MENU = 'KITCHEN_APP_HIDE_TEST_ORDER_MENU'
const FEATURE_FLAG_GUIDED_TOUR_KEY = 'guided-tour:configuration'
const FEATURE_FLAG_GUIDED_TOUR_DISABLED = 'KITCHEN_APP_DISABLE_GUIDED_TOUR'
const FEATURE_FLAG_RECEIPT_USE_BARCODE_DISABLED_KEY =
	'kitchen-feature:disable-receipt-use-barcode'
const FEATURE_FLAG_RECEIPT_USE_BARCODE_DISABLED = 'TRUE'
const FEATURE_FLAG_USE_DAFT_KEY = 'kitchen-feature:use-daft'
const FEATURE_FLAG_USE_DAFT_DISABLED = 'DISABLED'

const FEATURE_FLAG_USE_NEW_UI_KEY = 'kitchen-feature:use-new-ui'
const FEATURE_FLAG_USE_NEW_UI_ENABLED = 'TRUE'

const FEATURE_FLAG_ALLOW_ORDER_CANCELLATION =
	'kitchen-feature:allow-order-cancellation'
const FEATURE_FLAG_ALLOW_ORDER_CANCELLATION_ENABLED = 'TRUE'

const FEATURE_FLAG_EPSON_PRINTER = 'kitchen-feature:epson-printer'
const FEATURE_FLAG_EPSON_PRINTER_ENABLED = 'TRUE'

export const shouldHideTestOrderMenu = (
	venueConfiguration: VenueConfiguration
) =>
	hasFeatureFlag(
		venueConfiguration,
		FEATURE_FLAG_TEST_ORDER_KEY,
		FEATURE_FLAG_TEST_ORDER_HIDE_MENU
	)

export const shouldDisableGuidedTour = (
	venueConfiguration: VenueConfiguration
) =>
	hasFeatureFlag(
		venueConfiguration,
		FEATURE_FLAG_GUIDED_TOUR_KEY,
		FEATURE_FLAG_GUIDED_TOUR_DISABLED
	)

export const shouldDisableUseReceiptBarcode = (
	venueConfiguration: VenueConfiguration
) =>
	hasFeatureFlag(
		venueConfiguration,
		FEATURE_FLAG_RECEIPT_USE_BARCODE_DISABLED_KEY,
		FEATURE_FLAG_RECEIPT_USE_BARCODE_DISABLED
	)

export const shouldPrintDaftCode = (venueConfiguration: VenueConfiguration) =>
	!hasFeatureFlag(
		venueConfiguration,
		FEATURE_FLAG_USE_DAFT_KEY,
		FEATURE_FLAG_USE_DAFT_DISABLED
	)

export const shouldUseNewUI = (venueConfiguration: VenueConfiguration) =>
	hasFeatureFlag(
		venueConfiguration,
		FEATURE_FLAG_USE_NEW_UI_KEY,
		FEATURE_FLAG_USE_NEW_UI_ENABLED
	)

export const shouldAllowOrderCancellation = (
	venueConfiguration: VenueConfiguration
) =>
	hasFeatureFlag(
		venueConfiguration,
		FEATURE_FLAG_ALLOW_ORDER_CANCELLATION,
		FEATURE_FLAG_ALLOW_ORDER_CANCELLATION_ENABLED
	)

export const shouldAllowEpsonPrinter = (
	venueConfiguration: VenueConfiguration
) =>
	hasFeatureFlag(
		venueConfiguration,
		FEATURE_FLAG_EPSON_PRINTER,
		FEATURE_FLAG_EPSON_PRINTER_ENABLED
	)

const hasFeatureFlag = (
	venueConfiguration: VenueConfiguration,
	featureFlagKey: string,
	featureFlagValue: string
) => {
	return (
		venueConfiguration?.feature_flags?.some(
			(featureFlag) =>
				featureFlag.key === featureFlagKey &&
				featureFlag.value === featureFlagValue
		) ?? false
	)
}
