import EpsonPrinter, { EpsonPrint } from '@baemingo/epson-printer-sdk'

type EpsonSendReceiptOptions = {
	receipt: EpsonPrint
	ip: string
}

export const epsonPrintReceipt = async ({
	ip,
	receipt
}: EpsonSendReceiptOptions) => {
	if (ip === 'dev') {
		console.log(receipt.toString())
		return
	}

	const printer = new EpsonPrinter(ip)
	return printer.send(receipt)
}
