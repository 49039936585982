import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'

import { Dialog, DialogContent } from '@mui/material'

import { enqueueSnackbar } from 'notistack'

import { PreflightCheckValue } from '../../@types/PreflightCheck'
import {
	useCreatePreflightCheck,
	usePreflightChecks
} from '../../queries/preflightChecks'
import { BatteryCheck } from './BatteryCheck'
import { MultiCheck } from './MultiCheck'
import { PrinterCheck } from './PrinterCheck'
import { ActiveStep, Props } from './types'

const REFETCH_INTERVAL = 1000 * 60 * 15

export const PreflightCheck: FC<Props> = memo(
	// eslint-disable-next-line sonarjs/cognitive-complexity
	({ loadingStation, loadingArea, onNavigateToFAQ, menuActive }) => {
		const serviceAssignmentId = loadingStation.serviceassignment
		const stationName = loadingStation.name
		const [open, setOpen] = useState(false)
		const [activeStep, setActiveStep] = useState(ActiveStep.BATTERY)
		const [values, setValues] = useState<Record<string, any>>({})
		const handleReset = useCallback(() => {
			setValues({})
			setActiveStep(ActiveStep.BATTERY)
			setOpen(false)
		}, [])
		const {
			data: settings,
			error,
			isLoading
		} = usePreflightChecks(serviceAssignmentId, {
			refetchInterval: open ? undefined : REFETCH_INTERVAL
		})
		const { mutateAsync: createPreflightCheck } =
			useCreatePreflightCheck(serviceAssignmentId)
		const handleSubmit = useCallback(async () => {
			const convertedValues = Object.entries(values)
				.filter(([_, value]) => value !== undefined)
				.map<PreflightCheckValue>(([key, value]) => ({
					field: key,
					value: Array.isArray(value) ? value.join('\n') : value
				}))
			try {
				await createPreflightCheck(convertedValues)
				enqueueSnackbar('Preflight completed! Have a nice day 💫', {
					variant: 'success',
					autoHideDuration: 5000,
					anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
				})
			} catch {
				enqueueSnackbar(
					'Preflight failed! Please, restart the app and try again',
					{
						variant: 'error',
						autoHideDuration: 5000,
						anchorOrigin: {
							horizontal: 'center',
							vertical: 'bottom'
						}
					}
				)
			}
			handleReset()
		}, [createPreflightCheck, handleReset, values])

		const handleNavigateToPrinterCheck = useCallback(() => {
			setValues((currentValues) => ({
				...currentValues,
				battery_level: true
			}))
			const hasPrintingStep = !!settings?.fields?.find(
				(group) => group.field === 'testing_printer'
			)?.field
			setActiveStep(
				hasPrintingStep ? ActiveStep.PRINTER : ActiveStep.MULTIPLE
			)
		}, [settings?.fields])
		const handleNavigateToMultiCheck = useCallback(() => {
			setValues((currentValues) => ({
				...currentValues,
				battery_level: true,
				testing_printer: true
			}))
			setActiveStep(ActiveStep.MULTIPLE)
		}, [])
		const handleChange = useCallback((values: any) => {
			setValues((currentValues) => ({
				...currentValues,
				...values
			}))
		}, [])

		useEffect(() => {
			if (error || !settings?.fields?.length) {
				setOpen(false)
			}
			if (!!settings?.fields?.length && !settings?.completed) {
				setOpen(true)
				setValues(
					Object.fromEntries(
						settings.fields.map((step) => [step.field, step.value])
					)
				)
			}
		}, [settings, error])
		const activeChecks = useMemo(() => {
			if (!settings?.fields?.length) {
				return []
			}
			if (activeStep === ActiveStep.BATTERY) {
				return settings?.fields?.filter(
					(group) => group.field === 'battery_level'
				)
			}
			if (activeStep === ActiveStep.PRINTER) {
				return settings?.fields?.filter(
					(group) => group.field === 'testing_printer'
				)
			}
			return settings?.fields?.filter(
				(group) =>
					!['battery_level', 'testing_printer'].includes(group.field)
			)
		}, [activeStep, settings?.fields])
		if (isLoading || menuActive) {
			return null
		}

		return (
			<>
				<Dialog open={open} maxWidth="sm" fullWidth>
					<DialogContent>
						{activeStep === ActiveStep.BATTERY && (
							<BatteryCheck
								onNext={handleNavigateToPrinterCheck}
							/>
						)}
						{activeStep === ActiveStep.PRINTER && (
							<PrinterCheck
								onNext={handleNavigateToMultiCheck}
								onNavigateToFAQ={onNavigateToFAQ}
								loadingStation={loadingStation}
								loadingArea={loadingArea}
							/>
						)}
						{activeStep === ActiveStep.MULTIPLE && (
							<MultiCheck
								loadingArea={loadingArea}
								values={values}
								checks={activeChecks}
								stationName={stationName}
								onChange={handleChange}
								onNavigateToFAQ={onNavigateToFAQ}
								onNext={handleSubmit}
							/>
						)}
					</DialogContent>
				</Dialog>
			</>
		)
	}
)
