import { enqueueSnackbar } from 'notistack'

export const enqueuePrintErrorSnackbar = (message: string) => {
	enqueueSnackbar(message, {
		variant: 'error',
		autoHideDuration: 2000,
		anchorOrigin: {
			horizontal: 'center',
			vertical: 'bottom'
		}
	})
}

export const enqueuePrintSuccessSnackbar = (message: string) => {
	enqueueSnackbar(message, {
		variant: 'default',
		autoHideDuration: 2000,
		anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
	})
}
