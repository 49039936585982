import React from 'react'

import _isObject from 'lodash/isObject'
import PropTypes from 'prop-types'

import { useSettingsContext } from '../../../components/SettingsContext'
import { usePrintReportReceipt } from '../../../hooks/printReceipt/usePrintReportReceipt'
import { usePrinterStatus } from '../../../hooks/usePrinterStatus'
import { ReactComponent as EvenNumbers } from '../../../icons/EvenNumbers.svg'
import { ReactComponent as GreenArrowUp } from '../../../icons/GreenArrowUp.svg'
import { ReactComponent as PrinterWhite } from '../../../icons/PrinterWhite.svg'
import { ReactComponent as RedArrowDown } from '../../../icons/RedArrowDown.svg'
import {
	calculateTimelyOrder,
	formatCurrencyLocale,
	postDebugEvent
} from '../../../utils'
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator'
import './styles.scss'

export const ReportSummary = ({ summary, currency, cursor }) => {
	const { count, completed, cancelled, timely, totalSum } = summary
	const { settings } = useSettingsContext()
	const printerStatus = usePrinterStatus(settings.usePrinter)
	const printReportReceipt = usePrintReportReceipt()

	const initiatePrint = (summary, currency, cursor) => {
		printReportReceipt(summary, currency, cursor)
		postDebugEvent('printDailyReport')
	}

	return summary !== undefined && _isObject(count) ? (
		<div className={'report-summary-container'}>
			{printerStatus.printerStatus?.isOnline && (
				<button
					onClick={() =>
						initiatePrint(summary, currency, cursor.current)
					}
					className={'report-summary-print-button'}>
					<span className={'print-img'}>
						<PrinterWhite />
					</span>
					<span className={'print-text'}>PRINT REPORT</span>
				</button>
			)}
			<div className={'report-summary-blocks'}>
				<div className={'report-summary'}>
					<span className={'summary-header'}>ORDERS</span>
					<div className={'summary-spacer'}>
						<span className={'summary-number'}>{count.value}</span>
						<span className={'summary-img'}>
							{count.delta === 0 ? (
								<EvenNumbers />
							) : count.delta < 0 ? (
								<RedArrowDown />
							) : (
								<GreenArrowUp />
							)}
						</span>
					</div>
				</div>
				<div className={'report-summary'}>
					<span className={'summary-header'}>CANCELLED ORDERS</span>
					<div className={'summary-spacer'}>
						<span className={'summary-number'}>
							{cancelled.value}
						</span>
						<span className={'summary-img'}>
							{cancelled.delta === 0 ? (
								<EvenNumbers />
							) : cancelled.delta < 0 ? (
								<RedArrowDown />
							) : (
								<GreenArrowUp />
							)}
						</span>
					</div>
				</div>
				<div className={'report-summary'}>
					<span className={'summary-header'}>COMPLETED ORDERS</span>
					<div className={'summary-spacer'}>
						<span className={'summary-number'}>
							{completed.value}
						</span>
						<span className={'summary-img'}>
							{completed.delta === 0 ? (
								<EvenNumbers />
							) : completed.delta < 0 ? (
								<RedArrowDown />
							) : (
								<GreenArrowUp />
							)}
						</span>
					</div>
				</div>
				<div className={'report-summary'}>
					<span className={'summary-header'}>TIMELY ORDERS</span>
					<div className={'summary-spacer'}>
						<span
							className={
								'summary-number'
							}>{`${calculateTimelyOrder(
							summary.timely.value,
							count.value
						)}%`}</span>
						<span className={'summary-img'}>
							{timely.delta === 0 ? (
								<EvenNumbers />
							) : timely.delta < 0 ? (
								<RedArrowDown />
							) : (
								<GreenArrowUp />
							)}
						</span>
					</div>
				</div>
				<div className={'report-summary'}>
					<span className={'summary-header'}>
						TOTAL SUM (EX. TAX)
					</span>
					<div className={'summary-spacer'}>
						<span className={'summary-number'}>
							{formatCurrencyLocale(totalSum.value, currency)}
						</span>
						<span className={'summary-img'}>
							{totalSum.delta === 0 ? (
								<EvenNumbers />
							) : totalSum.delta < 0 ? (
								<RedArrowDown />
							) : (
								<GreenArrowUp />
							)}
						</span>
					</div>
				</div>
			</div>
		</div>
	) : (
		<LoadingIndicator />
	)
}

ReportSummary.propTypes = {
	summary: PropTypes.objectOf({
		timely: PropTypes.object,
		completed: PropTypes.object,
		cancelled: PropTypes.object,
		totalSum: PropTypes.object,
		count: PropTypes.object
	}),
	currency: PropTypes.string.isRequired,
	cursor: PropTypes.object.isRequired
}
